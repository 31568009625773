import type { IStyleSheetConfig } from '@fluentui/merge-styles';
import { mergeStyles, Stylesheet } from '@fluentui/merge-styles';
import { memoizeFunction } from '@fluentui/utilities';

declare global {
  interface Window {
    // @ts-ignore this global will barf without an ignore because Fluent already globally defines it but with a different sig than is implemented
    FabricConfig?: {
      mergeStyles?: IStyleSheetConfig;
    };
  }
}

let navScrollBarWidth = 0;
let currentZoomLevel = 0;
let styleTagRef: HTMLStyleElement | undefined;
let widthVarIndex: number;
const widthChangeCallbacks: ((width: number) => void)[] = [];

/**
 * This function creates an element with scroll overflow and then measures the difference in offset and client widths
 * and also checks to ensure we aren't running the function unnecessarily in the case that it is triggered without
 * a zoom level change.
 * @param nextZoomLevel pixel ratio as a number
 * @returns the width of the scrollbar at the given zoom level
 */
function getScrollBarWidth(nextZoomLevel: number): number {
  if (
    (navScrollBarWidth !== 0 && currentZoomLevel === nextZoomLevel) ||
    typeof window === 'undefined'
  ) {
    return navScrollBarWidth;
  }

  currentZoomLevel = nextZoomLevel;
  // Get the browser scrollbar width because they're all different
  const scrollDiv: HTMLDivElement = document.createElement('div');

  scrollDiv.setAttribute(
    'class',
    mergeStyles({
      width: 100,
      height: 100,
      overflow: 'scroll',
      position: 'absolute',
      top: -999,
    }),
  );
  const contentDiv: HTMLElement = document.createElement('div');

  contentDiv.setAttribute('class', mergeStyles({ width: 100, height: 200 }));
  scrollDiv.appendChild(contentDiv);
  document.body.appendChild(scrollDiv);
  navScrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  // fire callbacks with updated width value.
  widthChangeCallbacks.forEach((callback: (width: number) => void) => {
    callback(navScrollBarWidth);
  });

  return navScrollBarWidth;
}

/**
 * Function that sets a css variable to the given scrollbar width in the root scope
 * @param width width of the scrollbar
 */
const setScrollBarWidth = (width: number) => {
  // If we're running on the server, there is no `window` object
  // so there's no point in trying to run the rest of this function
  if (typeof window === 'undefined') {
    return;
  }

  // If we have a ref to our stylesheet containing our variable, update it instead of inserting again
  if (styleTagRef?.parentElement && styleTagRef?.sheet) {
    if (widthVarIndex !== undefined) {
      styleTagRef.sheet.deleteRule(widthVarIndex);
    }
    // inject updated css var
    widthVarIndex = styleTagRef.sheet.insertRule(
      `:root {--scrollbar-width: ${width}px;}`,
    );
  } else {
    if (styleTagRef) {
      document.getElementsByTagName('head')[0].removeChild(styleTagRef);
    }
    /**
     * Get CSP safe style tag to inject scrollbar width variable.
     * To avoid breaking changes to consumers or require additional work, we're going to pull the nonce from either the global config object or the Stylesheet object. _config is not a public API of Stylesheet, but it's the only way to get the nonce without requiring consumers to pass it in. This may be brittle which is why it's a fallback option if no global config is present.
     */
    const nonce =
      // @ts-ignore this global will barf without an ignore because Fluent already globally defines it but with a different sig than is implemented
      (window.FabricConfig?.mergeStyles as IStyleSheetConfig)?.cspSettings?.nonce ??
      // @ts-ignore _config is private but we need to grab it as a fallback anyways
      (Stylesheet.getInstance()._config as IStyleSheetConfig)?.cspSettings?.nonce;

    // create new style tag and insert css var (first run)
    styleTagRef = document.createElement('style');

    if (nonce) {
      styleTagRef.setAttribute('nonce', nonce);
    }
    document.getElementsByTagName('head')[0].appendChild(styleTagRef);
    widthVarIndex = (styleTagRef.sheet as CSSStyleSheet)?.insertRule(
      `:root {--scrollbar-width: ${width}px;}`,
    );
  }
};

// Memoize our function in the event it's called again and a value has been calculated already.
const memoizedScrollWidth = memoizeFunction(setScrollBarWidth, 1);

let animationFrameID: number | undefined;
let mql: MediaQueryList;

/**
 * A function that listens for changes in the display ratio (DPI) of the current app and sets a
 * CSS variable to to the width of the scroll bar in that current display ratio. The variable is set as
 * '--scrollbar-width' in the root. From there, additional rules can use this value for UI.
 */
export const insertScrollWidthAsCSSVar = () => {
  if (animationFrameID !== undefined) {
    cancelAnimationFrame(animationFrameID);
  }

  let devicePixelRatio = 1;

  if (typeof window !== 'undefined') {
    animationFrameID = requestAnimationFrame(() => {
      if (mql) {
        mql.onchange = null;
      }
      mql = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
      mql.onchange = insertScrollWidthAsCSSVar;
      animationFrameID = undefined;
    });

    devicePixelRatio = window.devicePixelRatio;
  }

  memoizedScrollWidth(getScrollBarWidth(devicePixelRatio));
};

insertScrollWidthAsCSSVar();

/**
 * This function allows a consumer to get a callback when the width of a scrollbar is updated. This tends to
 * be trigged via a display change or a DPI change on a current display.
 * @param callback a callback function that will be called when the width of the scrollbar is updated
 */
export const registerCallback = (callback: (width: number) => void) => {
  // avoid inserting the same callback multiple times.
  if (!widthChangeCallbacks.includes(callback)) {
    widthChangeCallbacks.push(callback);
    // fire once on registration so the consumer has an up to date value
    callback(navScrollBarWidth);
  }
};

/**
 * This function unregisters a callback that was previously registered. This should be done when unmounting
 * components to avoid leaks.
 * @param callback a reference to a callback that was previously registered
 */
export const unregisterCallback = (callback: (width: number) => void) => {
  const callbackIndex = widthChangeCallbacks.indexOf(callback);

  if (callbackIndex !== -1) {
    widthChangeCallbacks.splice(callbackIndex, 1);
  }
};
